export const getURLSearchParams = <T extends Record<string, any>>(
  params: Partial<Record<keyof T, string>>
) => {
  const urlParams = new URLSearchParams(window.location.search);

  const searchParams = Object.entries(params).reduce(
    (acc, [propName, urlParameterName]) => {
      const urlParameterValue = urlParams.get(urlParameterName as string);

      return urlParameterValue
        ? {
            ...acc,
            [propName]: urlParameterValue,
          }
        : acc;
    },
    {} as T
  );

  return searchParams;
};
