import 'normalize.css';

import { datadogRum } from '@datadog/browser-rum';
import React, { Suspense, useEffect } from 'react';
import { lazily } from 'react-lazily';
import { PopupLearnMoreContainer } from 'src/components/popup-learn-more-container';
import { WIDGET_INITIALIZED } from 'src/constants/common';
import {
  AnalyticsProvider,
  AnalyticsProviderProps,
  useAnalyticsContext,
} from 'src/context/analytics-context';
import {
  AppProvider,
  MerchantProps,
  useAppContext,
} from 'src/context/store/app-provider';
import { useGetMerchantId } from 'src/context/store/use-get-merchant-id';
import { useOnExperimentsReady } from 'src/hooks/use-on-experiments-ready';
import { initializeDatadogRUMWebview } from 'src/utils/datadog';
import { getURLSearchParams } from 'src/utils/get-url-search-params';
import { renderToDecorator } from 'src/utils/render-to-decorator';

const { PayInFullContent } = lazily(
  () => import('src/components/popup-learn-more-pay-in-full')
);
const { NonStandartLearnMore } = lazily(
  () => import('src/components/non-standart-learn-more')
);
const { LearnMoreWithSchedule } = lazily(
  () => import('src/components/learn-more-with-schedule')
);
const { LearnMoreWithInstallmentsCalculator } = lazily(
  () => import('src/components/learn-more-with-installments-calculator')
);

export function InstallmentsWebview() {
  const {
    getExperiment,
    experimentsStatus,
    currency,
    typeOfContentPopup,
    dispatch,
  } = useAppContext();
  const getMerchantId = useGetMerchantId();
  const experiment2431 = getExperiment('CT-2431-pay-now') || 'control';

  const { sendLearnMoreOpenEvent } = useAnalyticsContext();

  useEffect(() => {
    if (experimentsStatus === 'done') {
      getMerchantId(currency).then((merchantId) => {
        sendLearnMoreOpenEvent(merchantId);
      });
    }
  }, [sendLearnMoreOpenEvent, getMerchantId, currency, experimentsStatus]);

  useEffect(() => {
    dispatch({
      type: 'updateScheduleType',
    });
  }, [dispatch]);

  useOnExperimentsReady(initializeDatadogRUMWebview);

  const renderContent = () => {
    if (experiment2431 !== 'control') {
      return <PayInFullContent />;
    }

    if (typeOfContentPopup === 'schedule') {
      return <LearnMoreWithSchedule />;
    }

    if (typeOfContentPopup === 'calculator') {
      return <LearnMoreWithInstallmentsCalculator />;
    }

    return <NonStandartLearnMore />;
  };

  return (
    <PopupLearnMoreContainer
      style={{
        alignItems: 'stretch',
        ...(experiment2431 !== 'control'
          ? {
              padding: 0,
              borderRadius: 0,
              height: '100%',
            }
          : null),
      }}
    >
      <Suspense fallback={null}>{renderContent()}</Suspense>
    </PopupLearnMoreContainer>
  );
}

declare global {
  interface Window {
    InstallmentsWebview: (
      props: { selector: string } & MerchantProps &
        Omit<AnalyticsProviderProps, 'children'>
    ) => void;
  }
}

window.InstallmentsWebview = ({ selector, ...props }) => {
  const searchParams = getURLSearchParams<typeof props>({
    lang: 'lang',
    currency: 'currency',
    installmentsCount: 'installmentsCount',
    price: 'price',
    partnerCharges: 'partnerCharges',
  });

  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'InstallmentsWebview',
    ...props,
  });

  renderToDecorator(
    selector,
    <AppProvider {...props} {...searchParams}>
      <AnalyticsProvider props={{ selector, ...props, ...searchParams }}>
        <InstallmentsWebview />
      </AnalyticsProvider>
    </AppProvider>
  );
};
