import { useEffect, useState } from 'react';

export const ResizeStrategy = {
  greater: 'greater',
  greaterAndEqual: 'greaterAndEqual',
  less: 'less',
  lessAndEqual: 'lessAndEqual',
} as const;

export type ResizeStrategy = typeof ResizeStrategy[keyof typeof ResizeStrategy];

export const useResize = (strategy: ResizeStrategy, breakpoint: number) => {
  const [isMatched, setIsMatched] = useState(false);

  useEffect(() => {
    const resizeHandler = (event: UIEvent) => {
      switch (true) {
        case strategy === ResizeStrategy.greater:
          setIsMatched(
            Number((event.currentTarget as Window).innerWidth) > breakpoint
          );
          break;
        case strategy === ResizeStrategy.greaterAndEqual:
          setIsMatched(
            Number((event.currentTarget as Window).innerWidth) >= breakpoint
          );
          break;
        case strategy === ResizeStrategy.less:
          setIsMatched(
            Number((event.currentTarget as Window).innerWidth) < breakpoint
          );
          break;
        case strategy === ResizeStrategy.lessAndEqual:
          setIsMatched(
            Number((event.currentTarget as Window).innerWidth) <= breakpoint
          );
          break;
        default:
          setIsMatched(false);
      }
    };

    resizeHandler({
      currentTarget: window,
    } as any);

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [breakpoint, strategy]);

  return isMatched;
};
