import cn from 'classnames';
import React from 'react';
import { useAppContext } from 'src/context/store/app-provider';
import { ResizeStrategy, useResize } from 'src/hooks/use-resize';

import styles from './styles.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  mobileResize?: number;
}

export function PopupLearnMoreContainer({
  children,
  className,
  style,
  mobileResize = 340,
  ...props
}: Props) {
  const { isRtl, isMobileRounded } = useAppContext();
  const isMobile = useResize(ResizeStrategy.lessAndEqual, mobileResize);

  return (
    <div
      id="TabbyDialogContainer"
      className={cn(styles.body, className, {
        [styles.bodyRtl]: isRtl,
        [styles.bodyRectangle]: !isMobileRounded,
      })}
      style={{
        padding: isMobile ? '16px' : '32px',
        ...style,
      }}
      data-test="widget-container"
      {...props}
    >
      {children}
    </div>
  );
}
